
import React from 'react'

const ProfileSkeleton = () => {
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-50 py-10 px-6">
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8 animate-pulse">
        <div className="h-6 bg-gray-200 rounded-md mb-6 w-1/3 mx-auto"></div>
        <div className="flex justify-center mb-6">
          <div className="w-24 h-24 bg-gray-200 rounded-full"></div>
        </div>
        <div className="space-y-4">
          <div className="h-5 bg-gray-200 rounded-md"></div>
          <div className="h-5 bg-gray-200 rounded-md"></div>
          <div className="h-5 bg-gray-200 rounded-md"></div>
          <div className="h-5 bg-gray-200 rounded-md"></div>
          <div className="h-5 bg-gray-200 rounded-md"></div>
          <div className="h-5 bg-gray-200 rounded-md"></div>
          <div className="h-5 bg-gray-200 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton