import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { addMultipleCards } from "../../../redux/feature/card/slice";

const CheckoutForm = ({ setCard, ConnAccId, CusId, clientId, CardType, fetchData, onClose, stripeActivation }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  console.log("Connected Account Id Here:", ConnAccId);
  const cusid = CusId;
  const cardtype = CardType;

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   setProcessing(true);

  //   const cardElement = elements.getElement(CardElement);

  //   if (clientId) {
  //     const result = await stripe.createPaymentMethod(
  //       {
  //         type: "card",
  //         card: cardElement,
  //       },
  //       {
  //         customer: cusid,
  //       },
  //       {
  //         stripeAccount: ConnAccId,
  //       }
  //     );
  //     if (result.error) {
  //       console.log(result.error.message);
  //     } else {
  //       console.log(result.paymentMethod.id, "Payment Method ID");
  //       // Handle successful payment method creation
  //       const pmid = result.paymentMethod.id;
  //       // setCard(result.paymentMethod);
  //       setCard(result.paymentMethod);
  //       dispatch(addMultipleCards({ pmid, cusid, cardtype, clientId }));
  //     }
  //     fetchData();
  //     onClose();
  //   }
  //   else {
  //     const result = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: cardElement,
  //     }, {
  //       stripeAccount: ConnAccId,
  //     });

  //     if (result.error) {
  //       setError(result.error.message);
  //       setProcessing(false);
  //     } else {
  //       setError(null);
  //       setCard(result.paymentMethod);
  //       setProcessing(false);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);

    const cardElement = elements.getElement(CardElement);

    try {
      if (clientId) {
        const result = await stripe.createPaymentMethod(
          {
            type: "card",
            card: cardElement,
          },
          {
            customer: CusId,
          },
          {
            stripeAccount: ConnAccId,
          }
        );

        if (result.error) {
          console.log(result.error.message);
          setError(result.error.message);
        } else {
          console.log(result.paymentMethod.id, "Payment Method ID");
          setCard(result.paymentMethod);
          dispatch(addMultipleCards({
            pmid: result.paymentMethod.id,
            cusid: CusId,
            cardtype: CardType,
            clientId,
          }));
        }

        fetchData();
        onClose();
      } else {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        }, {
          stripeAccount: ConnAccId,
        });

        if (result.error) {
          setError(result.error.message);
        } else {
          setCard(result.paymentMethod);
          onClose();
          setError(null);
        }
      }

      // Clear the card input fields
      if (cardElement) {
        cardElement.clear();
      }
    } catch (err) {
      console.error(err);
      setError("An unexpected error occurred.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement className="form-control card-input" />
      {/* {error && <div className="error">{error}</div>} */}
      <button className="btn btn-primary mt-3" type="submit" disabled={!stripe || processing} style={{
        width: '150px', // Adjust width as needed
        backgroundColor: 'rgb(5, 140, 189)', // Set button color
        borderColor: 'rgb(5, 140, 189)', // Ensure border matches button color
        display: 'block', // Make the button a block element
        marginLeft: 'auto', // Align to the right
      }}>
        {processing ? 'Adding...' : 'Add'}
      </button>
    </form>
  );
};

export default CheckoutForm;
