"use client";
// import Button from "../button/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import {
//     selectLoading,
// } from "@/redux/feature/client/slice";
import { addMultipleCards } from "../../redux/feature/card/slice";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

// import Swal from 'sweetalert2';

function AddCard({
  fetchData,
  onClose,
  CusId,
  ConnAccId,
  Cardtype,
  clientId,
  isAdd,
  setCard,
}) {
  useEffect(() => {
    if (!ConnAccId || !CusId || !Cardtype) {
      console.warn("Missing required props: ConnAccId, CusId, or Cardtype");
    }
  }, [ConnAccId, CusId, Cardtype]);

  const stripe = useStripe();
  const elements = useElements();
  // const loading = useSelector(selectLoading);
  const connctedaccountid = ConnAccId;
  const cusid = CusId;
  const cardtype = Cardtype;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(ConnAccId);
    console.log(CusId);
    console.log(Cardtype);
    console.log(clientId);
  }, [ConnAccId, CusId, Cardtype]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe or elements not available");
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);
      const result = await stripe.createPaymentMethod(
        {
          type: "card",
          card: cardElement,
        },
        {
          customer: cusid,
        },
        {
          stripeAccount: connctedaccountid,
        }
      );

      if (result.error) {
        console.log(result.error.message);
      } else {
        console.log(result.paymentMethod.id, "Payment Method ID");
        // Handle successful payment method creation
        const pmid = result.paymentMethod.id;
        if (isAdd === true) {
          setCard(result.paymentMethod);
        } else {
          setCard(result.paymentMethod);
          dispatch(addMultipleCards({ pmid, cusid, cardtype, clientId }));
        }
      }
      // result.paymentMethod
      onClose();
      fetchData();

    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  return (
    <div className="fixed inset-0 h-full flex items-center justify-center bg-gray-500 bg-opacity-50 mb-10">
      <div className="bg-white p-12 rounded-lg w-96">
        <h2 className="text-center text-lg font-semibold mt-1 mb-4">Add Card</h2>

        <div className="">
          <label className="mt-4 font-semibold" htmlFor="cardNumber">
            Card Details
          </label>
          <CardElement className="mt-2 text-sm border disabled:opacity-50 border-[#929292] rounded-md overflow-hidden w-full  p-3 indent-1" />
        </div>
        <div className="flex justify-start mt-5 gap-4">
          <button
            onClick={handleSubmit}
            className="border rounded-lg py-2 px-8 text-[18px]"
            style={{
              backgroundColor: '#058CBD',
              color: '#FFFFFF',
            }}
          >
            Add
          </button>
          <button
            onClick={onClose}
            className="border rounded-lg py-2 px-6 text-[18px]"
            style={{
              backgroundColor: '#058CBD',
              color: '#FFFFFF',
            }}
          >
            Close
          </button>
          {/* <Button onClick={onClose} name={"Cancel"} />
          <Button onClick={handleSubmit} name={"Add"} /> */}
        </div>
      </div>
    </div>
  );
}

export default AddCard;
