import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function Counter({ increment, decrement, text, onCounterChange }) {
    const [counter, setCounter] = useState(parseInt(text));

    const onIncrement = () => {
        const newCounter = counter + 1;
        setCounter(newCounter);
        onCounterChange(newCounter);
    };

    const onDecrement = () => {
        if (counter > 0) {
            const newCounter = counter - 1;
            setCounter(newCounter);
            onCounterChange(newCounter);
        }
    };

    const buttonStyle = {
        backgroundColor: '#058CBD',
        borderColor: '#058CBD',
        color: 'white',
    };

    // return (
    //     <div style={{width: '80%'}} className="counter d-flex align-items-center shadow-sm justify-content-between rounded overflow-hidden">
    //     <button
    //         type="button"
    //         onClick={onDecrement}
    //         className="counter-button btn px-3 py-2 rounded-start"
    //         style={buttonStyle}
    //     >
    //         -
    //     </button>
    //     <p className="counter-value bg-light flex-grow-1 text-center fs-4 m-0">{counter}</p>
    //     <button
    //         type="button"
    //         onClick={onIncrement}
    //         className="counter-button btn px-3 py-2 rounded-end"
    //         style={buttonStyle}
    //     >
    //         +
    //     </button>
    // </div>
    // );

    return (
        <div className=" flex counter-button items-center shadow-md justify-between w-full rounded-[8px] overflow-hidden">
            <button
                type="button"
                onClick={onDecrement}
                // className="bg-gradient-to-b from-blue-300 to-[#0298CC] text-white px-[12px] py-[8px] hover:bg-blue-700 transition duration-200 ease-in-out rounded-l-lg focus:outline-none"
                className="bg-gradient-to-b text-white px-[12px] py-[8px] transition duration-200 ease-in-out rounded-l-lg focus:outline-none"
            >
                -
            </button>
            <p className="mb-0 bg-gray-100 py-[4px] flex-1 text-center text-xl font-medium">{counter}</p>
            <button
                type="button"
                onClick={onIncrement}
                // className="bg-gradient-to-b from-blue-300 to-[#0298CC] text-white px-[12px] py-[8px] hover:bg-blue-700 transition duration-200 ease-in-out rounded-r-lg focus:outline-none"
                className="bg-gradient-to-b text-white px-[12px] py-[8px] transition duration-200 ease-in-out rounded-r-lg focus:outline-none"
            >
                +
            </button>
        </div>
    );
}

export default Counter;
