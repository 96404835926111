import React, { useEffect, useState } from "react";
import Nav from "../navbar/navigation";
import "./back.css";
import Car2 from "../formsCard/car2.png";
import FormContainer from "./forminfo";
import Choosevehicle from "./choosevehicle";
import Byname from "./byname";
import Confirm from "./confirm";
import Stepper1 from "../img/stepper1.svg";
import Stepper2 from "../img/stepper2.svg";
import Stepper3 from "../img/stepper3.svg";
import Back from "../img/back.svg";
import { Steps, Checkbox, Modal, Select } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCompany, selectCompany } from "../../redux/feature/company/slice";
import SignIn from "./SignIn/SignIn";
import Cookies from "js-cookie";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import {
  getSingleReservation,
  selectSingleReservation,
} from "../../redux/feature/reservation/slice";
import ParentProfile from "../profile/ParentProfile";
import OrderHistory from "./OrderHistory";
import Loader from "../newLoader/loader";

const { Step } = Steps;

function Background() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const reservation = useSelector(selectSingleReservation);
  const { companyId, resId, isTrip } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [isToggleOn, setIsToggleOn] = useState(true);
  const [formData, setFormData] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [isByNameChecked, setIsByNameChecked] = useState(false);
  const [isByTypeChecked, setIsByTypeChecked] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const company = useSelector(selectCompany);
  // Assuming company has `backgroundColor` and `textColor`

  useEffect(() => {
    const fetchCompany = async () => {
      await dispatch(getCompany(companyId));
      setLoading(false);
    };
    fetchCompany();
  }, [companyId, dispatch]);


  let backgroundColor;
  let textColor;

  useEffect(() => {
    if (company?.backgroundColor) {
      backgroundColor = company?.backgroundColor || "#058CBD";
    }
    if (company?.textColor) {
      textColor = company?.textColor;
    }
  }, [company]);

  console.log("Company Here:", company);


  useEffect(() => {
    if (backgroundColor && textColor) {
      document.documentElement.style.setProperty(
        "--btn-bg-color",
        backgroundColor
      );
      document.documentElement.style.setProperty("--btn-text-color", textColor);
      document.documentElement.style.setProperty("--text-color", textColor);
    }
  }, [backgroundColor, textColor, company]);

  useEffect(() => {
    if (company)
    {
      document.title = `${company?.name} Booking App`;
    }
    else
    {
      document.title = `Limo Booking App`;
    }
    
  }, [company]);

  useEffect(() => {
    if (company && company?.logo)
    {
      const setFavicon = (url) => {
        const link = document.querySelector("link[rel='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "icon";
        link.href = url;
        document.head.appendChild(link);
      };

      setFavicon(company?.logo);
    }
    else
    {
      const setFavicon = (url) => {
        const link = document.querySelector("link[rel='icon']") || document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "icon";
        link.href = url;
        document.head.appendChild(link);
      };

      setFavicon("/Logo-Xtreim.png");
    }
    
  }, [company]);

  const handleToggle = () => {
    setIsToggleOn(!isToggleOn); // Toggle the state
  };

  const handleStepClick = (clickedStep) => {
    setCurrentStep(clickedStep);
  };

  const goNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const goNextData = (Data) => {
    setFormData(Data);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const goNextVehicle = (vehicle) => {
    setVehicleData(vehicle);
    console.log(vehicle)
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleByNameChange = (e) => {
    setIsByNameChecked(e.target.checked);
    setIsByTypeChecked(!e.target.checked); // Ensure only one checkbox is checked at a time
  };

  const handleByTypeChange = (e) => {
    setIsByTypeChecked(e.target.checked);
    setIsByNameChecked(!e.target.checked); // Ensure only one checkbox is checked at a time
  };

  const renderStepperImage = () => {
    switch (currentStep) {
      case 1:
        return (
          <img
            src={Stepper1}
            alt="Step 1"
            className="img-fluid img-responsive"
          />
        );
      case 2:
        return (
          <img
            src={Stepper2}
            alt="Step 2"
            className="img-fluid img-responsive"
          />
        );
      case 3:
        return (
          <img
            src={Stepper3}
            alt="Step 3"
            className="img-fluid img-responsive"
          />
        );
      default:
        return null;
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // Function to handle step change (AntD Steps component automatically handles it)
  const handleStepChange = (newStep) => {
    // Ensure we stay within bounds (0 to steps.length - 1)
    if (newStep >= 0 && newStep < steps.length) {
      setCurrentStep(newStep + 1);
    }
  };
  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  const steps = [
    {
      title: 'Request Info',
      content: <FormContainer goNext={goNextData} hours={company?.bookingHours} />,
    },
    {
      title: "Choose Vehicle",
      content: (
        <>
          <div className="d-flex flex-wrap justify-content-between align-items-center w-100 mb-3">
            <div className="d-flex justify-content-start ms-5">
              <h1>Popular Cars</h1>
            </div>
          </div>
          {/* {formData ? (
            <Choosevehicle
              goNext={goNextVehicle}
              formData={formData}
              companyId={companyId}
            />
          ) : ( */}
          <Byname
            goNext={goNextVehicle}
            formData={formData}
            companyId={companyId}
          />
          {/* )} */}
        </>
      ),
    },
    {
      title: "Confirm",
      content: (
        <Confirm
          resId={resId}
          isTrip={isTrip}
          vehicle={vehicleData}
          ConnAccId={company?.stripeId}
          CompanyId={company?._id}
          CreditCard={company?.requireCC}
          DBA={company?.dbas}
          URL={company?.bookingThankYouPage}
          pricingFormat={company?.pricingFormat}
          payOption={company?.payOption}
        />
      ),
    },
  ];

  const [signInSuccess, setSignInSuccess] = useState(false);
  const clientId = Cookies.get("client_id");

  useEffect(() => {
    if (clientId) {
      setSignInSuccess(true);
    }
  }, [clientId]);

  const handleSuccessfullSignIn = () => {
    setSignInSuccess(true);
  };

  const [option, setOption] = useState("home");

  const handleChange = (value) => {
    console.log("Selected:", value);
    setOption(value);
    // if (value === "home") {
    //   navigate(`/company/${companyId}`);
    // }
    // else if (value === "profile") {
    //   navigate(`/company/client/${clientId}`);
    // }
    // else if (value === "orderHistory") {
    //   navigate(`/company/client/orderHistory/${clientId}`);
    // }
    // else if (value === "logout") {
    //   Cookies.remove("client_id");
    //   Cookies.remove("phoneNumber");
    //   Cookies.remove("email");
    //   window.location.reload();
    // }
    // else {
    //   navigate(`/company/${companyId}`);
    // }

    if (value === "logout") {
      Cookies.remove("client_id");
      Cookies.remove("phoneNumber");
      Cookies.remove("email");
      window.location.reload();
    }
  };

  return (
    <>
      {loading ? (
        <div className="fixed w-[80%] h-[100%] sm:h-[100%] sm:w-[100%] flex items-center justify-center z-50">
          <Loader size={150} />
        </div>
      ) : (
        <div className="container-fluid scale-90-laptop">
          <div className="container position-relative bg-white rounded responsive-scale">
            <div className="row ms-lg-5 ms-0 lg:px-5 hidden sm:block">
              <div className="col-md-12 text-center">
                <img
                  src={
                    company?.banner ||
                    "https://firebasestorage.googleapis.com/v0/b/dooms-prod.appspot.com/o/widget_photos%2F8I769QDA_IMG_7644.jpg?alt=media&token=8567b233-08f5-438d-8679-365c58c1f4e9"
                  }
                  alt="New Image"
                  className="img-fluid img-responsive company-banner"
                // style={{ maxHeight: '300px', width: 'auto' }}
                />
              </div>
            </div>

            <div className="row mt-3 align-items-center me-lg-5 me-0">
              <div className="col-md-4 text-center text-md-start d-flex justify-content-center">
                <div className="logo-container  align-items-center">
                  <img
                    src={company?.logo || "/logoFinal.png"}
                    alt="Company Logo"
                    className="company-logo img-fluid img-responsive"
                    style={{ maxWidth: "120px", height: "auto" }}
                  />
                </div>
              </div>
              <div className="col-md-4 text-center my-2 my-md-0">
                <h1 className="font-weight-600" style={{ fontSize: "1.8rem" }}>
                  {company?.name || "Xtreim"}
                </h1>
              </div>
              <div className="col-md-4 text-center text-md-end">
                {signInSuccess ? (
                  <Select
                    placeholder="Menu"
                    className="custom-select w-40 h-16 py-2"
                    onChange={handleChange}
                    dropdownClassName="custom-dropdown"
                    // bordered={false}
                    defaultValue={option}
                  >
                    <Select.Option value="home">
                      <div className="flex items-center gap-2">
                        <Icon icon="mdi:home" width={24} height={24} className="text-lg" />
                        <span>Home</span>
                      </div>
                    </Select.Option>
                    <Select.Option value="profile">
                      <div className="flex items-center gap-2">
                        <Icon icon="mdi:account" width={24} height={24} className="text-lg" />
                        <span>Profile</span>
                      </div>
                    </Select.Option>
                    <Select.Option value="orderHistory">
                      <div className="flex items-center gap-2">
                        <Icon icon="mdi:history" width={24} height={24} className="text-lg" />
                        <span>Order History</span>
                      </div>
                    </Select.Option>
                    <Select.Option value="logout">
                      <div className="flex items-center gap-2">
                        <Icon icon="mdi:logout" width={24} height={24} className="text-lg text-red-500" />
                        <span className="text-red-500">Log Out</span>
                      </div>
                    </Select.Option>
                  </Select>
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "rgb(5, 140, 189)",
                      color: "white",
                      borderRadius: "8px",
                      fontSize: "16px",
                      padding: "12px 20px",
                      border: "none",
                    }}
                    onClick={showModal}
                  >
                    LOGIN
                  </button>
                )}
              </div>
            </div>
            {(option === "home" || option === "logout") && (
              <>
                <div className="stepper-container d-flex flex-row flex-md-column align-items-center my-2 mr-16">
                  <Steps
                    current={currentStep - 1}
                    labelPlacement="vertical"
                    onChange={handleStepChange}
                    // size={"small"}
                    responsive={true}
                    className="w-50"
                    size="default"
                    style={{ width: "800px" }}
                  >
                    {steps.map((step, index) => (
                      <Step key={index} title={step.title} />
                    ))}
                  </Steps>
                </div>
                <div className="stepper-container text-center">
                  <div className="d-flex align-items-center justify-content-start sm:ms-[65px]">
                    {currentStep == 3 && (
                      <div className="my-4">
                        <img
                          src={Back}
                          alt="Back"
                          className="img-fluid img-responsive"
                          style={{ width: '24px', height: '24px', cursor: 'pointer', marginRight: '30px', marginTop: '8px' }}
                          onClick={goBack}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {currentStep === 1 && (
                  <FormContainer goNext={goNextData} hours={company?.bookingHours} companyId={company?._id} />
                )}
                {currentStep === 2 && (
                  <>
                    <div className="d-flex flex-wrap justify-content-between align-items-center w-100 mt-5 mb-2">
                      <div className="d-flex justify-content-start sm:ms-28">
                        {currentStep >= 2 && (
                          <div className="">
                            <img
                              src={Back}
                              alt="Back"
                              className="img-fluid img-responsive"
                              style={{ width: '24px', height: '24px', cursor: 'pointer', marginRight: '30px', marginTop: '8px' }}
                              onClick={goBack}
                            />
                          </div>
                        )}
                        <h2>Popular Cars</h2>
                      </div>
                    </div>
                    {/* {isByNameChecked ?
                <Choosevehicle
                  goNext={goNextVehicle}
                  formData={formData}
                  companyId={companyId}
                />
                : */}
                    <Byname
                      goNext={goNextVehicle}
                      formData={formData}
                      companyId={companyId}
                    />
                    {/* } */}
                  </>
                )}
                {currentStep === 3 &&
                  <Confirm
                    resId={resId}
                    isTrip={isTrip}
                    vehicle={vehicleData}
                    ConnAccId={company?.stripeId}
                    CompanyId={company?._id}
                    CreditCard={company?.requireCC}
                    DBA={company?.dbas?.[0] || company?._id}
                    URL={company?.bookingThankYouPage}
                    pricingFormat={company?.pricingFormat}
                    payOption={company?.payOption}
                    stripeAct={company?.stripeActivation}
                  />
                }
              </>
            )}
            {option === "profile" && (
              <>
                <ParentProfile clientId={clientId} company={company} />
              </>
            )}
            {option === "orderHistory" && (
              <>
                <OrderHistory clientId={clientId} company={company} />
              </>
            )}

          </div>
          <br />
          <Modal
            title="Sign In"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            centered
          >
            <SignIn onClose={handleCancel} companyId={company?._id} companyName={company?.name} companyLogo={company?.logo} handleSuccess={handleSuccessfullSignIn} />
          </Modal>
        </div>
      )}
    </>

  );
}

export default Background;
