import React, { useState, useEffect } from "react";
import { DatePicker as AntDatePicker } from "antd";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";

const { RangePicker } = AntDatePicker;

function DatePicker({ setSelectedDate, setStartDate, setLastDate, reservationpage }) {
  // const [selectedDates, setSelectedDates] = useState([
  //   dayjs().startOf("month"),
  //   dayjs(),
  // ]);
  const [selectedDates, setSelectedDates] = useState(() => {
    return reservationpage ? [] : [dayjs().startOf("month"), dayjs()];
  });

  useEffect(() => {
    const formattedDates = selectedDates.map((date) =>
      date.format("MM-DD-YYYY")
    );
    console.log("Initial selectedDates:", formattedDates);
    // handleDateChange(selectedDates);  // Initialize dates on mount
  }, []);

  const handleDateChange = (dates) => {
    console.log("New Values are  ", dates);

    if (dates && dates.length >= 2) {
      const startDate = dates[0];
      const endDate = dates[1];

      setStartDate(startDate.format("MM-DD-YYYY"));
      setLastDate(endDate.format("MM-DD-YYYY"));

      const datesInRange = dates.map((date) => date.format("MM-DD-YYYY"));
      setSelectedDates(dates);
      setSelectedDate(datesInRange);
      console.log("Here are dates in the range: ", datesInRange);
    } else {
      setSelectedDates([]);
      setStartDate(null);
      setLastDate(null);
      setSelectedDate([]);
    }
  };

  return (
    <div className="flex bg-[#fafafa] flex-row items-center px-2 rounded-md justify-center border ls:h-[32px] ls:w-[250px]">
      <Icon
        className="text-md text-[#058CBD]"
        icon="solar:calendar-bold-duotone"
      />
      <RangePicker
        format="MM-DD-YYYY"
        value={selectedDates}
        onChange={handleDateChange}
        className="custom-range-picker border-transparent !important bg-transparent !important"
        dropdownClassName="custom-date-picker-dropdown"
        style={{
          borderColor: "transparent !important",
          backgroundColor: "#fafafa",
          hover: "none",
          boxShadow: "none",
        }}
        allowClear
      />
    </div>
  );
}

export default DatePicker;
