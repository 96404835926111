import React, { useState, useEffect } from "react";
import Cards from "./Cards";
import Profile from "./Profile";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import {
  getClientInfo,
  selectClientInfo,
} from "../../redux/feature/client/slice"; //Used to get client info but from backed and also need Auth
import { getCompany, selectCompany } from "../../redux/feature/company/slice";
// import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
// import { selectClient } from "../../redux/feature/client/slice";
//Used to get client info from redux store, after the user login

const ParentProfile = ({clientId, company}) => {
  // const { clientId } = useParams();

  const dispatch = useDispatch();

  // const company = useSelector(selectCompany);
  
  const clientInfo = useSelector(selectClientInfo);
  
  console.log(clientInfo?.company)

  const fetchData = async () => {
    try {
      dispatch(getClientInfo(clientId));
      // dispatch(getCompany(clientInfo?.company));

    } catch (error) {
      console.error("Error fetching client info:", error);
    }
  };
  useEffect(() => {

    if (clientId) {
      fetchData();
    }
  }, []);

  const [activeTab, setActiveTab] = useState("0");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  console.log("Client ID:", clientInfo);
  const CompArray = [
    <Profile id={clientId} client={clientInfo} />,
    <Cards company={company} clientId={clientId} fetchData={fetchData} clientInfo={clientInfo} />,
  ];
  return (
    <div className="flex flex-col py-3 custom-px sm:px-20 mt-4">
      
      <Tabs size="large" activeKey={activeTab} onChange={handleTabChange}>
        <TabPane tab="Profile" key="0">
          {CompArray[activeTab]}
        </TabPane>
        <TabPane tab="Cards" key="1">
          {CompArray[activeTab]}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ParentProfile;
