import React from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import 'bootstrap/dist/css/bootstrap.min.css';

function MapAddress({
    name,
    value,
    onChange,
    hasError,
    children,
    onIconClick,
    getFullDetails,
    errorMessage,
}) {
    const {
        getPlacePredictions,
        placesService,
        placePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: "AIzaSyBia7fF0j5nyIGAYq6c3sxk4a4bOpyL6Jw",
    });

    const handleSelect = (placeId, address) => {
        placesService?.getDetails({placeId}, (result, status) => {
            if (status === window?.google.maps.places.PlacesServiceStatus.OK) {
                const lat = result.geometry.location.lat();
                const lng = result.geometry.location.lng();

                // Initialize variables for city, country, zip code, and state
                let city = "";
                let country = "";
                let zipCode = "";
                let state = "";

                console.log("Result", result);

                // Loop through address components to find city, country, zip code, and state
                result.address_components.forEach((component) => {
                    if (component.types.includes("locality")) {
                        city = component.long_name;
                    } else if (component.types.includes("country")) {
                        country = component.long_name;
                    } else if (component.types.includes("postal_code")) {
                        zipCode = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1")) {
                        state = component.long_name; // Assign the state
                    }
                });

                // Return the details including the state
                getFullDetails({
                    address: address,
                    lat: lat,
                    lon: lng,
                    zipCode: zipCode,
                    city: city,
                    country: country,
                    state: state,
                });
            }
        });
    };

    const renderItem = (item, index) => {
        console.log("Items", item);
        return (
            <div className={`border ${index === 0 ? "rounded-top" : ""} ${index === placePredictions.length - 1 ? "rounded-bottom" : "border-bottom-0"}`} style={{width: '95%'}}>
                <div
                    className={`bg-white hover:bg-dark transition duration-200 ease-in-out border-bottom p-2 ${index === 0 ? "rounded-top" : ""} ${index === placePredictions.length - 1 ? "rounded-bottom border-bottom-0" : ""}`}
                    onClick={() => {
                        // getFullDetails ? handleSelect(item.place_id, item.description) : "";
                        if (getFullDetails) {
                            handleSelect(item.place_id, item.description);
                        }
                        onChange(item.description);
                        getPlacePredictions({input: ""});
                    }}
                    style={{cursor: 'pointer'}}
                    key={item.place_id}
                >
                    {item.description}
                </div>
            </div>
        );
    };

    return (
        <div className="position-relative">
            <input
                placeholder="Search for a location"
                value={value}
                className="form-control"
                onChange={(evt) => {
                    // Limit predictions to the USA
                    getPlacePredictions({
                        input: evt.target.value,
                        componentRestrictions: {country: "US"},
                    });
                    onChange(evt.target.value);
                }}
                loading={isPlacePredictionsLoading}
                style={{
                    width: '100%',
                    padding: '8px',
                    border: '1px solid ',
                    borderColor: '#ccc',
                    backgroundColor: '#faf9f9',
                    borderRadius: '4px'
                }}
            />

            {placePredictions && placePredictions.map((item, index) => {
                return (
                    <div key={index} className="">{renderItem(item, index)}</div>)
            })}

            {hasError && (
                <div>
                    <p className="text-danger m-2">{errorMessage}</p>
                </div>
            )}
            {children && (
                <div
                    onClick={onIconClick}
                    className={`absolute top-0 right-0 text-sm justify-center align-center flex items-center rounded-md overflow-hidden bg-[#0065B6]/10 px-1 text-[27px] text-[#058CBD] cursor-pointer`}
                    style={{height: "40px", width: "40px"}}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

export default MapAddress;
