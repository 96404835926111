import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleReservation,
  // selectReservationLoading,
  selectSingleReservation,
  DownloadSingleReservation,
} from "../../redux/feature/reservation/slice";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Table,
  Typography,
  Progress,
  Spin,
  Badge,
  Tooltip,
  Button,
  Select,
} from "antd";
import { formatedPrice, formatTime } from "../../utils/helper";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import GoogleMap from "./GoogleMap/GoogleMap";
import { debounce } from "lodash";
import Reservation from "./Reservation";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
const { Title, Text } = Typography;

function ReservationDetails() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyName, resId, tripId } = useParams();
  // const loading = useSelector(selectReservationLoading);
  console.log("selectedTripId", tripId);
  console.log("Reservation Id", resId);

  const reservation = useSelector(selectSingleReservation);
  console.log("reservation", reservation);
  const [loader, setLoader] = useState(true);
  //   const remainingAmount = trip?.total - trip?.paid;
  //   const progressPercentage = Math.round((trip.paid / trip.total) * 100);
  const allTrips = reservation?.trips;

  const trip = allTrips?.find((trip) => trip._id === tripId);
  console.log("trip", trip);

  useEffect(() => {
    if (resId) {
      dispatch(getSingleReservation({ id: resId, isTrip: false }));
    }
    setLoader(false);
  }, [dispatch, resId]);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClick = debounce(async () => {
    setIsDownloading(true);
    try {
      await dispatch(DownloadSingleReservation({ id: trip._id, isTrip: true }));
      setIsDownloading(false);
    } catch (error) {
      console.error("Error during download dispatch:", error);
      setIsDownloading(false);
    }
  }, 500);

  const columns = [
    {
      title: "Vehicle Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Vehicle Type",
      dataIndex: ["type", "name"],
      key: "type.name",
      align: "center",
    },
    {
      title: "General",
      key: "general",
      align: "center",
      render: (text, record) => {
        const generalFeatures = record.features.general;
        const availableFeatures = Object.entries(generalFeatures)
          .filter(([_, feature]) => feature.isAvailable)
          .map(([_, feature]) => ({
            name: feature.name,
            icon: feature.icon,
          }));

        return (
          <Tooltip
            title={
              availableFeatures.length > 0 ? (
                <div>
                  {availableFeatures.map((feature, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        marginBottom: "5px",
                      }}
                    >
                      <Icon
                        icon={feature.icon}
                        style={{ color: "white", marginRight: "8px" }}
                      />
                      <span className="text-white">{feature.name}</span>
                    </div>
                  ))}
                </div>
              ) : (
                "No available features"
              )
            }
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {availableFeatures.length > 0 ? (
                <Icon
                  icon="fluent:cursor-hover-28-regular"
                  style={{ fontSize: "24px" }}
                />
              ) : (
                <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Multimedia",
      key: "multimedia",
      align: "center",
      render: (text, record) => {
        const multimediaFeatures = record.features.multimedia;
        const availableFeatures = Object.entries(multimediaFeatures)
          .filter(([_, feature]) => feature.isAvailable)
          .map(([_, feature]) => ({
            name: feature.name,
            icon: feature.icon,
          }));

        return (
          <Tooltip
            title={
              availableFeatures.length > 0 ? (
                <div>
                  {availableFeatures.map((feature, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <Icon
                        icon={feature.icon}
                        style={{ color: "white", marginRight: "8px" }}
                      />
                      <span className="text-white">{feature.name}</span>
                    </div>
                  ))}
                </div>
              ) : (
                "No available features"
              )
            }
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {availableFeatures.length > 0 ? (
                <Icon
                  icon="fluent:cursor-hover-28-regular"
                  style={{ fontSize: "24px" }}
                />
              ) : (
                <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Policies",
      key: "policies",
      align: "center",
      render: (text, record) => {
        const policies = record.features.policies;
        const availablePolicies = Object.entries(policies)
          .filter(([_, policy]) => policy.isAvailable)
          .map(([_, policy]) => ({
            name: policy.name,
            icon: policy.icon,
          }));

        return (
          <Tooltip
            title={
              availablePolicies.length > 0 ? (
                <div>
                  {availablePolicies.map((policy, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <Icon
                        icon={policy.icon}
                        style={{ color: "white", marginRight: "8px" }}
                      />
                      <span className="text-white">{policy.name}</span>
                    </div>
                  ))}
                </div>
              ) : (
                "No available policies"
              )
            }
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {availablePolicies.length > 0 ? (
                <Icon
                  icon="fluent:cursor-hover-28-regular"
                  style={{ fontSize: "24px" }}
                />
              ) : (
                <Icon icon="ph:empty" style={{ fontSize: "24px" }} />
              )}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const handleNaviagtion = (tripId) => {
    navigate(`/${companyName}/reservation/reservationDetails/${reservation._id}/${tripId}`);
  };

  const handleSelectChange = (value) => {
    // const selectedValue = e?.target?.value; // Safeguard with optional chaining

    if (!value) return console.log("first"); // Exit early if the value is undefined

    if (value === "new reservation") {
      navigate(`/${companyName}/${trip?.DBA}`);
    } else if (value === "new trip") {
      navigate(`/${companyName}/${trip?.DBA}/${trip.parentReservationId}/true`);
      // Add your logic here
    }
  };

  return (
    <div  className="checkout-page bg-gray-100  min-h-screen p-6">
      {loader || !trip?._id ? (
        <Spin size="large" className="text-blue-600" />
      ) : (
        <div className="checkout-content md:max-w-4xl mx-auto space-y-6">
          <div className="flex flex-row justify-between ">
            {/* Left Column */}
              <Select
                id="selectTrip"
                placeholder="Select Trip"
                onChange={(value) => handleNaviagtion(value)}
                className="w-[150px] h-[40px] rounded-lg border-2 border-[#058CBD] bg-white text-[#058CBD] font-semibold text-lg hover:bg-[#058CBD] hover:text-white transition-colors duration-300"
              >
                {allTrips.map((trip) => {
                  return <option value={trip._id}>{trip.tripId}</option>;
                })}
              </Select>{" "}
              <span className="text-xl font-semibold">
                {trip?.tripAccociateCompanyId?.name}
              </span>

            {/* Right Column */}
            <div className="flex flex-col items-end space-y-2">
                <span className="text-xl font-semibold">
                  Price - {formatedPrice(trip?.total)}
                </span>
              <div className="flex flex-row items-center space-x-2">
                <Select
                  placeholder="Add Trip"
                  className="w-[230px] h-[42px] rounded-lg border-2 border-[#058CBD] bg-white text-[#058CBD] font-semibold text-lg hover:bg-[#058CBD] hover:text-white transition-colors duration-300"
                  onChange={handleSelectChange}
                >
                  <option
                    value="new reservation"
                    className="hover:bg-blue-300 text-[#058CBD] p-0"
                  >
                    <button
                      onClick={(value) => handleSelectChange(value)}
                      className="font-light text-sm p-1 border-none bg-transparent text-[#058CBD]"
                    >
                      Add New Reservation
                    </button>
                  </option>
                  <option
                    value="new trip"
                    className="hover:bg-blue-300 text-[#058CBD] p-0"
                  >
                    <button
                      onClick={(value) => handleSelectChange(value)}
                      className="font-light text-sm p-1 border-none bg-transparent text-[#058CBD]"
                    >
                      {" "}
                      Add New Trip To Same Reservation
                    </button>
                  </option>
                </Select>
                <button
                  className="border-2 border-[#058CBD] rounded-md text-lg font-semibold px-3 py-2 hover:text-white flex items-center bg-white hover:bg-[#058CBD] text-[#058CBD] transition-colors duration-300"
                  onClick={() => handleDownloadClick()}
                >
                  {isDownloading ? (
                    <Icon
                      icon="line-md:loading-loop"
                      className="text-2xl"
                    />
                  ) : (
                    <Icon
                      icon="line-md:download-loop"
                      className="text-2xl"
                    />
                  )}
                  
                </button>
              </div>
            </div>
          </div>

          <div className="md:max-w-4xl mx-auto">
            <Card
              className="shadow-lg max-w-4xl"
              title={`Trip ID: ${trip?.tripId || "Loading..."}`}
            >
              <div className="flex wrap flex-col md:flex-row items-start h-full md:items-start md:gap-6 justify-center w-full p-2 text-xs md:text-sm">
                <div className="flex wrap flex-col items-start gap-3 w-full h-full mb-4 md:mb-0 ">
                  <div className="w-full flex wrap flex-row items-center justify-between text-[#121417] md:self-start">
                    {trip?.tripType && (
                      <div className="bg-[#ECE7E1] p-2 px-3 rounded-md font-medium text-center">
                        {trip?.tripType.charAt(0).toUpperCase() +
                          trip?.tripType.slice(1)}
                      </div>
                    )}
                    <div className="bg-[#ECE7E1] p-2 px-3  rounded-md font-medium text-center">
                      {trip?.orderType.charAt(0).toUpperCase() +
                        trip?.orderType.slice(1)}
                    </div>
                  </div>
                  <div className="w-full flex wrap items-center">
                    <Icon height={25} icon="bx:car" className="mr-3" />
                    <div>
                      <p>
                        {trip?.pickupAddress.address}
                        <br />
                        <div className="text-[#61758A]">
                          {trip?.pickupDate} | {formatTime(trip?.pickupTime)}
                        </div>
                      </p>
                    </div>
                  </div>
                  <motion.div
                    className="w-1 ml-3"
                    style={{
                      background:
                        "linear-gradient(to bottom, #058cbd 50%, transparent 50%)",
                      backgroundSize: "3px 10px",
                      backgroundRepeat: "repeat-y",
                    }}
                    initial={{ height: 0 }}
                    animate={{ height: "5vh" }}
                    transition={{ duration: 1, ease: "easeOut" }}
                  />
                  <div className="w-full flex wrap items-center">
                    <Icon height={25} icon="bx:car" className="mr-3" />
                    <div>
                      <p>{trip?.dropoffAddress.address}</p>
                    </div>
                  </div>
                </div>

                <div className="flex wrap flex-col w-full md:w-2/3 h-full items-end justify-between">
                  <div className="w-full mt-4 md:mt-0">
                    <div className="relative flex wrap border-2 overflow-hidden rounded-lg">
                      <GoogleMap
                        addressA={trip?.pickupAddress.address}
                        addressB={trip?.dropoffAddress.address}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="flex wrap flex-col md:flex-row h-full md:items-start md:gap-6 justify-center w-full md:text-sm shadow-lg">
            <Card
              title={
                <div className="flex items-center align-center">
                  <p className="text-white text-center mt-3">
                    Passenger Details
                  </p>
                  <img
                    className="w-10 h-10 ms-auto rounded-full shadow-md object-cover cursor-pointer"
                    src={
                      !trip?.customer?.picture
                        ? "https://www.svgrepo.com/show/61986/avatar.svg"
                        : trip?.customer?.picture
                    }
                    alt="Uploaded Picture"
                  />
                </div>
              }
              bordered={false}
              className="flex-1 "
            >
              <div className="flex wrap flex-col  gap-2 items-start justify-between">
                <div className="flex wrap justify-center mb-1 ">
                  <Icon
                    icon="material-symbols:person"
                    className="mr-2 font-semibold w-6 h-6"
                  />
                  <p>{`${trip?.customer?.firstName || ""} ${
                    trip?.customer?.middleName || ""
                  } ${trip?.customer?.lastName || ""}`}</p>
                </div>
                <div className="flex wrap justify-center  mb-1 ">
                  <Icon
                    icon="clarity:email-solid"
                    className="mr-2 font-semibold w-6 h-6"
                  />
                  <p>{trip?.customer?.email}</p>
                </div>
                <div className="flex wrap justify-center  mb-1 ">
                  <Icon
                    icon="ph:phone-fill"
                    className="mr-2 font-semibold w-6 h-6"
                  />
                  <p>{trip?.customer?.phone || trip?.customer?.phoneNumber}</p>
                </div>
              </div>

              {/* Info Cards (Passengers, Luggage, Car Seats) */}
              <div className="flex wrap flex-col md:flex-row gap-2 mt-2">
                {[
                  {
                    icon: "mdi:users",
                    label: "Passengers",
                    value: trip?.passenger,
                  },
                  {
                    icon: "material-symbols-light:luggage",
                    label: "Luggage",
                    value: trip?.lauggage,
                  },
                  {
                    icon: "mingcute:seat-fill",
                    label: "Car Seats",
                    value: trip?.carSeat,
                  },
                ].map((item, index) => (
                  <Card key={index} className="flex-1 border-2 ">
                    <div className="flex wrap justify-center items-center mb-1">
                      {/* <Icon icon={item.icon} className="mr-2 w-6 h-6" /> */}
                      <p className="">{item.label}</p>
                    </div>
                    <p className="text-xl text-center font-bold">
                      {item.value}
                    </p>
                  </Card>
                ))}
              </div>
            </Card>
          </div>
          <div>
            <Card className="shadow-lg" title="Vehicle">
              <div className="overflow-x-auto">
                <Table
                  columns={columns}
                  dataSource={[trip?.Vehicle]}
                  rowKey="_id"
                  pagination={false}
                  className="min-w-full"
                />
              </div>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReservationDetails;
