import {
  addCardAPI,
  deleteCardAPI,
  updateCardAPI,
  addMultipleCardsAPI
} from "../../../utils/api.js";
import { errorAlert, successAlert } from "../../../utils/helper.js";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const handleApiError = (error) =>
  error.response?.data || error.message || "An unexpected error occurred";


export const addCard = createAsyncThunk(
  "card/add-booking-card", 
  async (cardData, { rejectWithValue }) => {
  try {
    const response = await addCardAPI(cardData);
    return response.data;
  } catch (error) {
      return rejectWithValue(handleApiError(error));
  }
});
export const addMultipleCards = createAsyncThunk(
"card/add-multiple-cards", 
async ({pmid, cusid, cardtype, clientId}, { rejectWithValue }) => {
try {
  console.log("Enter the addMultipleCards API");
  const response = await addMultipleCardsAPI(pmid, cusid, cardtype, clientId);
  console.log("Exiting the addMultipleCards API");
  return response.data;
} catch (error) {
    return rejectWithValue(handleApiError(error));
}
});
export const updateCards = createAsyncThunk(
"card/update-cards", 
async ({pmid, cusid, clientId}, { rejectWithValue }) => {
try {
  const response = await updateCardAPI(pmid, cusid, clientId);
  return response.data;
} catch (error) {
    return rejectWithValue(handleApiError(error));
}
});
export const deleteCards = createAsyncThunk(
"card/delete-cards", 
async ({pmid, cusid, clientId}, { rejectWithValue }) => {
try {
  const response = await deleteCardAPI(pmid, cusid, clientId);
  return response.data;
} catch (error) {
    return rejectWithValue(handleApiError(error));
}
});

const initialState = {
  cards: [],
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  saving:false,
};

const cardSlice = createSlice({
  name: 'card',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder

    .addCase(addCard.pending, (state, action) => {
      state.error = null;
      state.saving=true;
      console.log("State",state.saving);
    })
    .addCase(addCard.fulfilled, (state, action) => {
      state.cards.push(action.payload);
      state.error = null;
      state.saving=false;
      successAlert("Card Has Been Charged.")
    })
    .addCase(addCard.rejected, (state, action) => {
      state.error = action.payload.message || 'Could Not Charge Card.';
      state.saving=false;
      errorAlert(action.payload.message || 'Could Not Charge Card.')
    })
    .addCase(updateCards.pending, (state, action) => {
      state.error = null;
      state.saving=true;
      console.log("State",state.saving);
    })
    .addCase(updateCards.fulfilled, (state, action) => {
      state.cards.push(action.payload);
      state.error = null;
      state.saving=false;
      successAlert("Card Has Been Updated.")
    })
    .addCase(updateCards.rejected, (state, action) => {
      state.error = action.payload.message || 'Could not update card.';
      state.saving=false;
      errorAlert("Could not update card.")
    })
    .addCase(deleteCards.pending, (state, action) => {
      state.error = null;
      state.saving=true;
      console.log("State",state.saving);
    })
    .addCase(deleteCards.fulfilled, (state, action) => {
      state.cards =  state.cards.filter(card => card.id !== action.payload.id);
      state.error = null;
      state.saving=false;
      successAlert("Card Has Been Deleted.")
    })
    .addCase(deleteCards.rejected, (state, action) => {
      state.error = action.payload.message || 'Could not delete card.';
      state.saving=false;
      errorAlert("Could not delete card.")
    })
    .addCase(addMultipleCards.pending, (state, action) => {
      state.error = null;
      state.saving=true;
      console.log("State",state.saving);
    })
    .addCase(addMultipleCards.fulfilled, (state, action) => {
      state.cards.push(action.payload);
      state.error = null;
      state.saving=false;
      successAlert("Card Has Been Added.")
    })
    .addCase(addMultipleCards.rejected, (state, action) => {
      state.error = action.payload.message || 'Could not add card.';
      state.saving=false;
      errorAlert("Could not add card.")
    })

  },
});

export const selectCards = (state) => state.card.cards;

export default cardSlice.reducer;