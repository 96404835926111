import React from "react";

export default function FilterComponent({ filterText, onFilter, onClear }) {
  const inputRef = React.useRef();

  return (
    <div className="relative">
      <div className="flex items-center h-[40px] ls:h-[32px] ls:w-[160px]">
        <span className="inset-y-0 left-0 pl-3 flex items-center ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            className="absolute text-[20px] ls:text-[16px] left-6 text-[#005E7C] cursor-pointer iconify iconify--ri"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m18.031 16.617l4.283 4.282l-1.415 1.415l-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9s9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617m-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.867-3.133-7-7-7s-7 3.133-7 7s3.133 7 7 7a6.977 6.977 0 0 0 4.875-1.975z"
            />
          </svg>
        </span>
        <input
          ref={inputRef}
          id="search"
          type="text"
          placeholder="Search..."
          aria-label="Search Input"
          value={filterText}
          onChange={onFilter}
          className="w-full border border-gray-300 rounded-md bg-[#fafafa] px-10 py-2 ls:py-1 ls:px-8 leading-tight focus:outline-none focus:border-blue-500 focus:bg-white"
        />
      </div>
      {/* {filterText && (
        <button
          className="absolute bg-white inset-y-0 right-0 px-4 py-2 ls:px-2 ls:py-1 ls:-mt-1 text-gray-500"
          onClick={onClear}
        >
          x
        </button>
      )} */}
    </div>
  );
}
