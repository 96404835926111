"use client"
import { deleteCards, updateCards } from "../../../redux/feature/card/slice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { PaymentIcon } from 'react-svg-credit-card-payment-icons';

import { Icon } from "@iconify/react";


function PaymentCard({ brand, cardNo, fetchData,clientId, cardId, CusId, defaultcard, onClose, method }) {

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdated, setIsUpdated]=useState(false);
  // const customer = useSelector(selectCustomer);
  const pmid = cardId;
  const cusid = CusId;
  useEffect(() => {
    fetchData()
  }, [isUpdated])
  


  const handleDeleteClick = async () => {
    try {
      await dispatch(deleteCards({ pmid, cusid, clientId })).unwrap(); 
      setIsUpdated((prev) => !prev);
      onClose();
    } catch (error) {
      console.error("Error deleting card:", error);
    }
  };
  
  const handleUpdateClick = async () => {
    try {
      await dispatch(updateCards({ pmid, cusid, clientId })).unwrap(); 
      setIsUpdated((prev) => !prev);
      onClose();
    } catch (error) {
      console.error("Error updating card:", error);
    }
  };
  

  const PaymentMethodCard = ({ method }) => {
    return (
        <div className="relative w-full min-w-lg p-6 sm:p-6 bg-gradient-to-r from-[#01A7DC] to-[#0A4676] rounded-xl shadow-lg text-white">
          {/* Top Section */}
          <div className="flex flex-wrap sm:flex-nowrap justify-between items-center mb-4 sm:mb-6">
            {/* Card Brand Icon */}
            <div className="w-10 sm:w-12 h-10 sm:h-12 bg-white rounded-full flex items-center justify-center shadow">
              <PaymentIcon
                type={method.card.brand.toLowerCase()}
                format="flatRounded"
                width={28}
                height={28}
              />
            </div>
            {/* Chip Icon */}
            {/* <div className="bg-yellow-400 w-10 h-7 rounded-sm"></div> */}
            <Icon icon="flat-color-icons:sim-card-chip" className="w-8 sm:w-10 h-5 sm:h-7" />
          </div>

          {/* Card Number */}
          <p className="text-base sm:text-lg font-mono font-bold text-white tracking-widest mb-4 sm:mb-6">
            **** **** **** {method.card.last4}
          </p>

          {/* Bottom Section */}
          <div className="flex justify-between items-center text-xs sm:text-sm">
            <div>
              {/* Expiry */}
              <p className="uppercase text-gray-300">Expires</p>
              <p className="text-white font-semibold">
                {method.card.exp_month}/{method.card.exp_year}
              </p>
            </div>
            <div>
              {/* Brand */}
              <p className="uppercase text-gray-300">Card Brand</p>
              <p className="text-white font-semibold">
                {method.card.brand.toUpperCase()}
              </p>
            </div>
          </div>
        </div>
    );
  };

  return (
    <div className="relative w-full lg:w-[55%] lg:text-lg md:text-sm px-4 sm:px-8 py-4 my-4 rounded-md flex flex-wrap sm:flex-nowrap justify-between items-center ">
      <div className="flex w-full  sm:w-full justify-center sm:justify-start">
        <PaymentMethodCard method={method} />
      </div>
      <div className="flex justify-end mt-4 sm:mt-0 w-full sm:w-auto">
        {defaultcard && (
          <div>
            <Icon icon="twemoji:credit-card" width="1.2rem" height="1.2rem" />
          </div>
        )}
        <DropdownMenu
        
        trigger={({ triggerRef, ...props }) => (
          <Icon
            {...props}
            ref={triggerRef}
            height={"20px"}
            className="text-[#0f0f0f]"
            icon="pepicons-pop:dots-y"
          />
        )}
        placement="bottom-start"
      >
        <DropdownItemGroup >
          <DropdownItem className="bg-white border-white hover:bg-gray-200 w-full" onClick={() => handleUpdateClick()}>Default</DropdownItem>
          <DropdownItem className="bg-white border-white hover:bg-gray-200 w-full" onClick={() => handleDeleteClick()}>Delete</DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
      </div>
    </div>
  );
}

export default PaymentCard;
