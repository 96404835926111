import React from "react";
import {BrowserRouter as Router, Routes, Route,} from "react-router-dom";
import Home from './components/mainCard/mainCard'
import Background from "./components/formsCard/background"
import { Provider } from "react-redux";
import store from "./redux/store";
import Profile from "./components/profile/Profile";
import ParentProfile from "./components/profile/ParentProfile";
import OrderHistory from "./components/formsCard/OrderHistory";
import ReservationDetails
 from "./components/formsCard/ReservationDetails";
function App() {
  return (
    <Provider store={store}> {/* Wrap the Provider around your app */}
      <Router>
        <Routes>
           {/* <Route path="/:companyName/client/:clientId" element={<ParentProfile />} /> 
           <Route path="/:companyName/client/orderHistory/:clientId" element={<OrderHistory />} /> */}
           <Route path="/:companyName/reservation/reservationDetails/:resId/:tripId" element={<ReservationDetails/>} />
          <Route path="/:companyName/:companyId/:resId/:isTrip" element={<Background />} />
          <Route path="/:companyName/:companyId" element={<Background />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
