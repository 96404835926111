import React, { useEffect, useState } from "react";
import { Tabs, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllClientReservations,
  selectReservation,
  selectReservationLoading,
} from "../../redux/feature/reservation/slice";
import FilterComponent from "./DataTable/SearchBar";
import DatePicker from "../datePicker/DatePicker";
import { formatedPrice, renderStatusBadge, formatDate } from "../../utils/helper";
import DataTable from "react-data-table-component";
import ReservationTrips from "./ReservationTrips";

const { TabPane } = Tabs;

const OrderHistory = ({ clientId, company }) => {
  const [activeInnerTab, setActiveInnerTab] = useState("All");
  const [activeOuterTab, setActiveOuterTab] = useState("All");
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortField, setSortField] = useState("pickupDate");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState([]);
  const [resetDate, setResetDate] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reservations = useSelector(selectReservation);
  const reservationData = reservations?.reservations || [];
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const loading = useSelector(selectReservationLoading);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (clientId) {
      const fetchData = async () => {
        const params = {
          page,
          perPage,
          sortField,
          search,
          sortDirection,
          lastDate: endDate,
          startDate: startDate,
          status: activeInnerTab,
          isQuote: activeOuterTab === "quotes" ? true : false,
          clientId,
        };
        setRefresh(true);
        await dispatch(getAllClientReservations(params));
        setRefresh(false);
      };
      fetchData();
      setFilteredData(reservationData);
    }
  }, [dispatch, clientId, page, perPage, search, activeInnerTab, activeOuterTab, sortDirection, endDate, startDate]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    setFilteredData(
      reservationData.filter((res) =>
        Object.values(res).some((field) =>
          String(field).toLowerCase().includes(value)
        )
      )
    );
  };

  const handleSort = (order) => {
    setSortDirection(order);
    const sorted = [...filteredData].sort((a, b) => {
      const dateA = new Date(a.pickupDate).getTime();
      const dateB = new Date(b.pickupDate).getTime();

      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
    setFilteredData(sorted);
  };

  const filterReservationsByDate = (reservationData, startDate, endDate) => {
    if (!startDate || !endDate) {
      setFilteredData(reservationData);
      return;
    }

    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    const filteredData = reservationData.filter((reservation) => {
      const reservationDate = new Date(reservation.pickupDate).getTime();
      return reservationDate >= start && reservationDate <= end;
    });

    setFilteredData(filteredData);
  };

  useEffect(() => {
    filterReservationsByDate(reservationData, startDate, endDate);
  }, [startDate, endDate]);

  const handleInnerTabChange = (key) => {
    setActiveInnerTab(key);
  };

  const handleOuterTabChange = (key) => {
    setActiveOuterTab(key);
  };

  const isLsScreen = window.matchMedia(
    "(min-width: 1024px) and (max-width: 1440px)"
  ).matches;

  const columns = [
    {
      name: "Res Details",
      selector: (row) => {
        return (
          <div
            className="flex flex-col items-start justify-center cursor-pointer"
          >
            <b className="text-black font-normal flex flex-row gap-1 items-center justify-center ls:text-[12px]">
              {row.resID}
              <div
                className={
                  "rounded-full px-1 py-0.5 w-5 ls:text-[10px] text-center text-xs font-normal text-white bg-[#058CBD]"
                }
              >
                {row.trips.length}
              </div>
              {row.affiliateType && row.affiliateType === "gnet" ? (
                <img
                  width="50px"
                  src="https://gnet.grdd.net/dashboard/images/gnet-logo_110615.png"
                  alt="Affiliate Gnet"
                />
              ) : null}
            </b>
            {/* <p className="text-xs ls:text-[11px] text-black/40">
              {formatDate(row.pickupDate)}
            </p> */}
          </div>
        );
      },
      sortable: true,
      sort: "pickupDate",
      sortFunction: (a, b) => a.resID?.localeCompare(b.resID),
    },
    {
      name: "Pickup Date",
      selector: (row) => <span className="ls:text-[12px]">{row.pickupDate}</span>,
    },
    {
      name: "Order Type",
      selector: (row) => (
        <span className="ls:text-[12px]">{row.orderType}</span>
      ),
      grow: 1.3,
    },
    {
      name: "Amount",
      selector: (row) => row.total,
      cell: (row) => (
        <span className="ls:text-[12px]">{formatedPrice(row.total)}</span>
      ),
    },
    {
      name: "Payment",
      selector: (row) => row.payStatus,
      cell: (row) => {
        var status = "unpaid";
        if (row.paid === 0) {
          status = "unpaid";
        } else if (row.paid < row.total) {
          status = "partial";
        } else {
          status = "paid";
        }

        return <span className="ls:text-[12px]">{renderStatusBadge(status)}</span>;
      },
    },
    {
      name: "Res Status",
      center: true,
      selector: (row) => (
        <span className="ls:text-[12px]">{renderStatusBadge(row.resStatus)}</span>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        paddingTop: "8px",
        paddingBottom: isLsScreen ? "6px" : "8px",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    headRow: {
      style: {
        important: "true",
        backgroundColor: "#f6f6f6",
        color: "#000",
        fontSize: "15px",
        textAlign: "center",
        justifyContent: "center",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    cells: {
      style: {
        whiteSpace: "pre-line",
      },
    },
    pagination: {
      pageButtonsStyle: {
        backgroundColor: "transparent !important",
        border: "none !important",

      },
    },
    expanderButton: {
      style: {
        backgroundColor: "transparent !important", // Removes the background color
        color: "black !important", // Use inherited text color
        border: "none !important", // Remove any border
        boxShadow: "none", // Remove shadow if present
        padding: "0px !important", // Remove padding
      },
    },
    // table: {
    //   style: {
    //     '@media (min-width: 1024px) and (max-width: 1440px)': {
    //       transform: 'scale(0.9)',
    //       // transformOrigin: 'top left',
    //     },
    //   },
    // },
  };

  return (
    <div className="md:text-sm text-xs py-3 custom-px lg:px-20 mt-8 scale-90-laptop">
      <Tabs defaultActiveKey="reservations" type="card" onChange={handleOuterTabChange}>
        <TabPane tab="Reservations" key="reservations">
          <Tabs defaultActiveKey="all" type="line" onChange={handleInnerTabChange}>
            <TabPane tab="All" key="All" />
            <TabPane tab="Open" key="Open" />
            <TabPane tab="Closed" key="Closed" />
          </Tabs>
        </TabPane>
        <TabPane tab="Quotes" key="quotes" onChange={handleOuterTabChange}>
          <Tabs defaultActiveKey="all" type="line" onChange={handleInnerTabChange}>
            <TabPane tab="All" key="all" />
            <TabPane tab="Open" key="open" />
            <TabPane tab="Closed" key="closed" />
          </Tabs>
        </TabPane>
      </Tabs>

      <div className="flex flex-col lg:flex-row gap-3 justify-between">
        <DatePicker
          setLastDate={setEndDate}
          setStartDate={setStartDate}
          setSelectedDate={setSelectedDate}
          reservationpage={true}
          onChange={(startDate, endDate) =>
            filterReservationsByDate(reservationData, startDate, endDate)
          }
        />
        <div className="sort-container flex gap-2 items-center">
          <FilterComponent
            onFilter={(e) => setSearch(e.target.value)}
            onClear={() => setSearch("")}
            filterText={search}
          />
          <label>Order</label>
          <Select
            value={sortDirection}
            onChange={(value) => setSortDirection(value)}
            className="rounded-md bg-[#fafafa] w-[160px] h-[40px] ls:w-[160px] ls:h-[32px]"
          >
            <option value="asc">Ascending</option>
            <option value="desc">Descending</option>
          </Select>
        </div>
      </div>

      <div className="bg-[#FFFFFF] my-4 pb-4 rounded-lg">
        <DataTable
          columns={columns}
          data={reservations?.reservations || []}
          striped={true}
          rowKey="key"
          fixedHeader
          pointerOnHover
          customStyles={customStyles}
          highlightOnHover
          onRowClicked={(row) =>
            navigate(`/${company?.name}/reservation/reservationDetails/${row._id}/${row.trips[0]._id}`)
          }
          persistTableHead
          fixedHeaderScrollHeight="100vh"
          expandableRows
          expandableRowsComponent={({ data }) => (
            <ReservationTrips data={data.trips} company={company?.name}/>
          )}
          responsive
          resizable
          progressPending={
            loading && reservations.length == 0 ? true : refresh ? true : false
          }
          onSort={(column, sortDirection) => {
            setSortField(column.sort);
            setSortDirection(sortDirection);
          }}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={(newPerPage, page) => {
            setPerPage(newPerPage);
            setPage(page);
          }}
          pagination
          paginationServer
          sortServer
          paginationTotalRows={reservations?.total}
          paginationPerPage={perPage}
          paginationDefaultPage={page}
          sortField={sortField}
          sortOrder={sortDirection}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
