import React from "react";

function Loader({ size }) {
  
  return (
    <div className="flex items-center justify-center">
      <div
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <img
          src="/infinite-spinner.svg"
          alt="Loading..."
          width={size}
          height={size}
        />
      </div>
    </div>
  );
}

export default Loader;