import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { Form, Formik, Field } from "formik";
import ProfileSkeleton from "./ProfileSkeleton";
import { useSelector, useDispatch } from "react-redux";
import { updateClientInfo } from "../../redux/feature/client/slice";
import Swal from "sweetalert2";



const Profile = ({ id,client, onSave }) => {
  const initialForm={
    homeAddress: "",
    workAddress: "",
  }
  useEffect(() => {
    setFormData(client);
   },[client])

  const [formData, setFormData] = React.useState(client);
  const dispatch = useDispatch();
  const clientId = id
  
 if (!client) {
  return <ProfileSkeleton />;
 }

  const handleInputChange = (e) => {
    const { name } = e.target;
      const value = e.target.value;
      setFormData({ ...formData, [name]: value });
      console.log("Form Data:", formData);
    }

  
  const handleUpdate =async () => {
    try {
      console.log("Sending ",formData);
      await dispatch(updateClientInfo({clientId ,formData}));
      Swal.fire({
        icon: "success",
        title: "Profile Updated",
        text: "Your profile has been updated successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      console.log("Sent");
    }
    catch (error) {
      console.error("Error updating client info:", error);
    }
  };


  return (
    <div className="min-h-screen flex flex-col items-center py-10 px-6">
      <div className="flex justify-center items-center">
        <h1 className="w-full mb-8 p-2 text-[24px]">Profile</h1>
      </div>
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-8">
        {/* <h1 className="text-3xl font-bold text-gray-800 mb-6">Welcome {client?.firstName}!</h1> */}

        <div className="flex flex-col items-center">
          {/* Profile Picture Section */}
          <div className="relative mb-6">
            <img
              className="md:w-44 md:h-44 w-28 h-28 rounded-full shadow-md object-cover"
              src={
                client?.picture ||
                "https://www.svgrepo.com/show/61986/avatar.svg"
              }
              alt={`${client?.firstName} Avatar`}
            />

          </div>
        </div>

        {/* Form Section */}
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          onSubmit={handleUpdate}
        >
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-6 mt-6"
            >
              {/* First Name */}
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-gray-600 mb-1 font-medium"
                >
                  First Name
                </label>
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={client?.firstName}
                  className="w-full border rounded-lg px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
                  disabled
                />
              </div>

              {/* Middle Name */}
              <div>
                <label
                  htmlFor="middleName"
                  className="block text-gray-600 mb-1 font-medium"
                >
                  Middle Name
                </label>
                <Field
                  id="middleName"
                  name="middleName"
                  type="text"
                  value={client?.middleName}
                  className="w-full border rounded-lg px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
                  disabled
                />
              </div>

              {/* Last Name */}
              <div>
                <label
                  htmlFor="lastName"
                  className="block text-gray-600 mb-1 font-medium"
                >
                  Last Name
                </label>
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={client?.lastName}
                  className="w-full border rounded-lg px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
                  disabled
                />
              </div>

              {/* Phone */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-gray-600 mb-1 font-medium"
                >
                  Phone
                </label>
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    borderRadius: "8px",
                    borderColor: "#ddd",
                  }}
                  country={"us"}
                  value={client?.phoneNumber}
                  disabled
                />
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-600 mb-1 font-medium"
                >
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  value={client?.email}
                  type="email"
                  className="w-full border rounded-lg px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
                  disabled
                />
              </div>

              {/* Home Address */}
              <div>
                <label
                  htmlFor="homeAddress"
                  className="block text-gray-600 mb-1 font-medium"
                >
                  Home Address
                </label>
                <Field
                  id="homeAddress"
                  name="homeAddress"
                  onChange={handleInputChange}
                  value={formData?.homeAddress}
                  Placeholder="Enter Your Home Address"
                  type="text"
                  className="w-full border rounded-lg px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
                />
              </div>

              {/* Work Address */}
              <div>
                <label
                  htmlFor="workAddress"
                  className="block text-gray-600 mb-1 font-medium"
                >
                  Work Address
                </label>
                <Field
                  id="workAddress"
                  name="workAddress"
                  PlaceHolder="Enter Your Work Address"
                  onChange={handleInputChange}
                  value={formData?.workAddress}
                  type="text"
                  className="w-full border rounded-lg px-4 py-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
                />
              </div>

              {/* Buttons */}
              <div className="flex justify-between mt-6">
                <button
                  type="submit"
                  className="px-6 py-2  rounded-lg shadow-md"
                >
                  Save Changes
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Profile;
