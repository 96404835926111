"use client";
// import { useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { formatedPrice, renderStatusBadge, formatDate } from "../../utils/helper";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ReservationTrips({data, company}) {
//   const router = useRouter();
  console.log("This is the data",data)
  const navigate = useNavigate();

  const columns = [
    {
      name: "Trip Details",
      selector: (row) => (
        <>
          <p className="text-[#058CBD] flex items-center justify-center ls:text-[12px] mb-0">
            {row.tripId}
          </p>
          {/* <p>{new Date(row.pickupDate)?.toLocaleDateString()}</p> */}
          <p className="ls:text-[11px] mb-0">{formatDate(row.pickupDate)}</p>
        </>
      ),
      sortable: true,
      sort: "createdAt",
      sortFunction: (a, b) => a.tripId?.localeCompare(b.tripId),
    },
    {
      name: "Contact",
      // center: true,
      // wrap: true,
      selector: (row) => (
        <div className=" w-full flex-col flex items-center justify-center">
          <p className="w-full text-start text-sm text-black ls:text-[12px] mb-0">
            {" "}
            {(row.customer?.firstName ||
              row.passenger_details?.firstName ||
              "") +
              " " +
              (row.customer?.middleName ||
                row.passenger_details?.middleName ||
                "") +
              " " +
              (row.customer?.lastName || row.passenger_details?.lastName || "")}
          </p>
          {/*<br/>*/}
          <p className="w-full text-xs ls:text-[11px] mb-0">
            {row.customer?.email || row.passenger_details?.email || ""}
          </p>
        </div>
      ),
      grow: 1.3,
    },
 
    {
      name: "Order Type",
      selector: (row) => {
        return <span className="ls:text-[12px]">{row.orderType}</span>;
      },
      // sortable: true,
      wrap: true,
      sort: "orderType",
      grow: 1.3,
    },
    {
      name: "Amount",
      selector: (row) => row.total,      // sortable: true,
      sort: "total",
      cell: (row) => {
        return <span className="ls:text-[12px]">{formatedPrice(row.total)}</span>; // Wrap formatted price in a span
      },
    },
    {
      name: "Payment",
      selector: (row) => row.payStatus,      // center: true,
      // sortable: true,
      sort: "payStatus",
      // cell: (row) => <PaymentStatusCell value={row.payStatus || "Unpaid"}/>,
      cell: (row) => {
        var status = "unpaid";
        if (row.paid === 0) {
          status = "unpaid";
        } else if (row.paid < row.total) {
          status = "partial";
        } else {
          status = "paid";
        }

        return <span className="ls:text-[12px]">{renderStatusBadge(status)}</span>;
      },
      center: true,
      
    },
    {
      name: "Trip Status",
      selector: (row) => {
        return <span className="ls:text-[12px]">{renderStatusBadge(row.tripStatus || "pending")}</span>;
      },
      // sortable: true,
      sort: "resStatus",
      center: true,
      minWidth: "160px",
    },

    // 
  ];

  const customStyles = {
    rows: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#058CBD",
        color: "#fff",
        fontSize: "15px",
        textAlign: "center",
        justifyContent: "center",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },

    cells: {
      style: {
        whiteSpace: "pre-line",
      },
    },
  };
  
// const data =[
//   {
//     "resID": "123456789",
//     "orderType": "Trip",
//     "total": 100,
//     "payStatus": "paid",
//     "tripStatus": "completed",
//     "customer": {
//       "firstName": "John",
//       "middleName": "Doe",
//       "lastName": "Smith",
//       "email": "johndoe@example.com",
//       "phoneNumber": "1234567890"
//     },
//     "passenger_details": {
//       "firstName": "Jane",
//       "middleName": "Doe",
//       "lastName": "Smith",
//       "email": "janedoe@example.com",
//       "phone": "9876543210"
//     }
//   },
// ]

  return (
    <div className="overflow-x-auto">
    <DataTable
      columns={columns}
      data={data}
      striped={true}
      fixedHeader
      highlightOnHover
      pointerOnHover
      onRowClicked={(row) => {
        // navigate(`/reservation/trip-reservationDetails/${row._id}`);
        navigate(`/${company}/reservation/reservationDetails/${row.parentReservationId}/${row._id}`);
        // console.log("row",row._id)
      }}
      className={"reservationTrips"}
      
      fixedHeaderScrollHeight="100vh"
      customStyles={customStyles}
      responsive
      resizable
      />    
      </div>
  );
}

export default ReservationTrips;
