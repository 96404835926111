import { Icon } from "@iconify/react";
import { Table } from "antd";
import React, { useState } from "react";
import ReservationTrips from "./ReservationTrips";
import { formatedPrice, renderStatusBadge } from "../../utils/helper";
import DataTable from "react-data-table-component";
import {  useNavigate } from "react-router-dom";
import { color } from "framer-motion";

const Reservation = ({ data }) => {
  const navigate = useNavigate();
  const isLsScreen = window.matchMedia(
    "(min-width: 1024px) and (max-width: 1440px)"
  ).matches;

  const [page, setPage] = useState(1);
  const [row, setRow] = useState();
  const [perPage, setPerPage] = useState(10);

  const columns = [
    {
      name: "Res Details",
      selector: (row) => {
        return <span className="ls:text-[12px]">{row.resID}</span>;
      },
      // sortable: true,
      sort: "pickupDate",
      // sortFunction: (a, b) => a.resID?.localeCompare(b.resID)
    },
    {
      name: "Pickup Date",
      selector: (row) => {
        return <span className="ls:text-[12px]">{row.pickupDate}</span>;
      },
      // sortable: true,
      sort: "pickupDate",
      // sortFunction: (a, b) => a.resID?.localeCompare(b.resID)
    },
    {
      name: "Order Type",
      selector: (row) => {
        const handleClick = () => {
          console.log("first");
        };
        return (
          <span onClick={handleClick} className="ls:text-[12px]">
            {row.orderType}
          </span>
        );
      },
      // sortable: true,
      wrap: true,
      sort: "orderType",
    },
    {
      name: "Amount",
      selector: (row) => row.total,
      // sortable: true,
      // sort: "total",
      cell: (row) => {
        const handleClick = () => {
          console.log("first");
        };
        return (
          <span onClick={handleClick} className="ls:text-[12px]">
            {formatedPrice(row.total)}
          </span>
        );
      },
    },
    {
      name: "Payment",
      selector: (row) => row.payStatus,
      minWidth: "120px",
      // sortable: true,
      sort: "payStatus",
      
      cell: (row) => {
        const handleClick = () => {
          console.log("first");
        };
        let status, bgColor, textColor, icon;
        if (row.payStatus === "paid") {
          status = "Paid";
          bgColor = "#DCFCE7"; // Light green background
          textColor = "#16A34A"; // Green text
          icon = "mdi:lock"; // Paid icon
        } else if (row.payStatus === "partial") {
          status = "Partial";
          bgColor = "#FEF9C3"; // Light yellow background
          textColor = "#D49F27"; // Yellow text
          icon = "mdi:alert-outline"; // Partial payment icon
        } else if (row.payStatus === "unpaid") {
          status = "Unpaid";
          bgColor = "#FEE2E2"; // Light red background
          textColor = "#DC2626"; // Red text
          icon = "mdi:alert-circle-outline"; // Unpaid icon
        }

        return (
          <div
            className="inline-flex items-center cursor-pointer rounded-lg hover:scale-105 font-normal whitespace-nowrap capitalize transition-transform duration-300 ease-in-out px-3 py-2"
            // style={{ backgroundColor: bgColor }}
          >
            <span className="ls:text-[12px]">{renderStatusBadge(status)}</span>
          </div>
        );
      },
    },
    {
      name: "Res Status",
      center: true,
      minWidth: "160px",
      selector: (row) => {
        return <span className="ls:text-[12px]">{renderStatusBadge(row.resStatus)}</span>;
      },
      // sortable: true,
      sort: "resStatus",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        paddingTop: "8px",
        // paddingBottom: isLsScreen ? "6px" : "8px",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    headRow: {
      style: {
        important: "true",
        backgroundColor: "#f6f6f6",
        color: "#000",
        fontSize: "15px",
        textAlign: "center",
        justifyContent: "center",
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    cells: {
      style: {
        whiteSpace: "pre-line",
      },
    },
    pagination: {
    pageButtonsStyle: {
        backgroundColor: "transparent !important",
        border: "none !important",
        
    },
  },
    expanderButton: {
      style: {
        backgroundColor: "transparent !important" , // Removes the background color
        color: "black !important", // Use inherited text color
        border: "none !important", // Remove any border
        boxShadow: "none", // Remove shadow if present
        padding: "0px !important", // Remove padding
      },
    },
    // table: {
    //   style: {
    //     '@media (min-width: 1024px) and (max-width: 1440px)': {
    //       transform: 'scale(0.9)',
    //       // transformOrigin: 'top left',
    //     },
    //   },
    // },
  };

  return (
    <div className="text-2xl overflow-x-auto">
      <DataTable
        columns={columns}
        highlightOnHover
        data={data}
        rowKey="key"
        customStyles={customStyles}
        onRowClicked={(row) => {
          navigate(`/reservation/reservationDetails/${row._id}/${row.trips[0]._id}`);
          // console.log("row",row._id)
        }}
        expandableRows
        expandableRowsComponent={({ data }) => (
          <ReservationTrips data={data.trips} />
        )}
        pagination={{
          pageSize: perPage,
          customStyles: {
            rowsPerPageOption: {
              style: {
                display: "none",
              },
            },
          },
          current: page,
          onChange: (currentPage) => setPage(currentPage),
          showQuickJumper: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        className="rounded-lg shadow-md text-black"
      />
    </div>
  );
};

export default Reservation;
