import { React, useEffect, useState } from "react";
import PaymentCard from "./ReusableCards/PaymentCard";
import { Elements } from "@stripe/react-stripe-js";
import clsx from "clsx";
import { loadStripe } from "@stripe/stripe-js";
import AddCard from "./AddCard";
import {Card} from "antd";

const Cards = ({ fetchData, company, clientInfo, clientId }) => {
  const [cardtype, setCardType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [stripepromise, setStripePromise] = useState();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [individualCards, setIndividualCards] = useState([]);
  const [corporateCards, setCorporateCards] = useState([]);
  const [stripeCusId, setStripeCusId] = useState("");
  const [stripeConnAccount, setStripeConnAccount] = useState("");
  const [paymentCards, setPaymentCards] = useState([]);
  const [cloverCards, setCloverCards] = useState();

  useEffect(() => {
    const stripeKey = process.env.REACT_APP_PUBLIC_STRIPE_KEY;
    const promise = loadStripe(stripeKey, {
      stripeAccount: stripeConnAccount,
    });
    // const promise = loadStripe(
    //   "pk_test_51OpwTTB0PwSGyFJmGKMdLeZ7KHi98fSbm7jIvDEPLMIgFyokAZx5vdgcgVFFmJ67dwPHIXmJ8HlpoRiudr1P3OMn00qqvPIKh0",
    //   { stripeAccount: stripeConnAccount }
    // );
    setStripePromise(promise);
  }, [stripeConnAccount]);

  const togglePopup = () => {
    setOpenModal(!openModal);
    setCardType("individual");
  };

  const togglePopup2 = () => {
    setOpenModal(!openModal);
    setCardType("corporate");
  };

  useEffect(() => {
    if (clientInfo?.paymentMethods) {
      setPaymentCards(clientInfo.paymentMethods);
    } else {
      setPaymentCards([]);
    }
  }, [clientInfo]);

  useEffect(() => {
    fetchData();
  }, [individualCards, corporateCards]);
  

  useEffect(() => {
    if (clientInfo?.stripeCusId) {
      setStripeCusId(clientInfo?.stripeCusId);
      setStripeConnAccount(clientInfo?.stripeConnAccId);
    }
  }, [clientInfo]);

  useEffect(() => {
    if (clientInfo?.cloverCards) {
      setCloverCards(clientInfo.cloverCards);
    } else {
      setCloverCards([]);
    }
  }, [clientInfo?.cloverCards]);

  const handleModalClose = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <div className="flex justify-center items-center p-4 md:p-4">
        <h1 className="text-lg md:text-xl font-semibold text-gray-800">
          Card Info
        </h1>
      </div>
      <div className="w-full flex flex-col items-start">
        <div className="flex flex-col gap-6 w-full">
          {/* Individual Cards Section */}
          <div className="flex flex-col gap-4">
            <Card className="shadow-lg" title="Individual Cards">
              <div className=" w-full gap-4">
                {paymentCards?.personalCards?.length === 0 ? (
                  <p className="text-sm md:text-base text-center italic font-medium text-gray-500">
                    No cards found
                  </p>
                ) : (
                  paymentCards?.personalCards?.map((paymentCard, index) => (
                    <PaymentCard
                      fetchData={fetchData}
                      clientId={clientId}
                      key={index}
                      cardId={paymentCard.id}
                      brand={paymentCard.card.brand}
                      cardNo={`****${paymentCard.card.last4}`}
                      cardExp={`${paymentCard.card.exp_month}/${paymentCard.card.exp_year}`}
                      CusId={stripeCusId}
                      onClose={() => setIsPopUpOpen(false)}
                      defaultcard={
                        clientInfo?.defaultPaymentMethod === paymentCard.id
                      }
                      method={paymentCard}
                    />
                  ))
                )}
              </div>
              <button
                className={clsx(
                  "w-full  sm:w-fit px-6 py-2 text-sm md:text-base font-medium rounded-md shadow disabled:bg-gray-400 disabled:cursor-not-allowed",
                  company?.stripeActivation === false && "bg-gray-300"
                )}
                onClick={
                  company?.stripeActivation === false ? undefined : togglePopup
                }
                disabled={!company?.stripeActivation}
              >
                + Add Card
              </button>
            </Card>
          </div>

          {/* Corporate Cards Section */}
          <div className="flex flex-col gap-4">
            <Card className="shadow-lg" title="Corporate Cards">
              <div className="w-full gap-4">
                {paymentCards?.corporateCards?.length === 0 ? (
                  <p className="text-sm md:text-base text-center italic font-medium text-gray-500">
                    No cards found
                  </p>
                ) : (
                  paymentCards?.corporateCards?.map((paymentCard, index) => (
                    <PaymentCard
                      fetchData={fetchData}
                      clientId={clientId}
                      key={index}
                      cardId={paymentCard.id}
                      brand={paymentCard.card.brand}
                      cardNo={`****${paymentCard.card.last4}`}
                      cardExp={`${paymentCard.card.exp_month}/${paymentCard.card.exp_year}`}
                      CusId={stripeCusId}
                      onClose={() => setIsPopUpOpen(false)}
                      defaultcard={
                        clientInfo?.defaultPaymentMethod === paymentCard.id
                      }
                      method={paymentCard}
                    />
                  ))
                )}
              </div>
              <button
                className={clsx(
                  "w-full sm:w-fit px-6 py-2 text-sm md:text-base font-medium rounded-md shadow disabled:bg-gray-400 disabled:cursor-not-allowed",
                  company?.stripeActivation === false && "bg-gray-300"
                )}
                onClick={
                  company?.stripeActivation === false ? undefined : togglePopup2
                }
                disabled={!company?.stripeActivation}
              >
                + Add Card
              </button>
            </Card>
          </div>
        </div>

        {/* Modal */}
        {openModal && stripepromise && (
          <Elements stripe={stripepromise}>
            <AddCard
              fetchData={fetchData}
              CusId={stripeCusId}
              ConnAccId={stripeConnAccount}
              Cardtype={cardtype}
              clientId={clientId}
              onClose={handleModalClose}
              setCard={(newCard) =>
                cardtype === "individual"
                  ? setIndividualCards((cards) => [...cards, newCard])
                  : setCorporateCards((cards) => [...cards, newCard])
              }
            />
          </Elements>
        )}
      </div>
    </>
  );
};

export default Cards;
