import { Icon } from "@iconify/react/dist/iconify.js";
import Swal from "sweetalert2";


const statusConfig = {
  success: {
    color: "text-green-600 bg-green-100",
    statuses: {
      paid: {
        icon: "mdi:cash-check",
      },
      completed: {
        icon: "mdi:check-circle-outline",
      },
      done: {
        icon: "mdi:check",
      },
      closed: {
        icon: "mdi:lock",
      },
    },
  },
  error: {
    color: "text-red-600 bg-red-100",
    statuses: {
      pending: {
        icon: "mdi:timer-sand",
      },
      failed: {
        icon: "mdi:close-circle-outline",
      },
    },
  },
  warning: {
    color: "text-yellow-600 bg-yellow-100",
    statuses: {
      partial: {
        icon: "mdi:alert-outline",
      },
      warning: {
        icon: "mdi:alert",
      },
      requested: {
        icon: "mdi:account-question",
      },
    },
  },
  info: {
    color: "text-[#058CBD] bg-[#c8ebf7]",
    statuses: {
      saved: {
        icon: "mdi:content-save",
      },
      confirmed: {
        icon: "mdi:check-decagram",
      },
      draft: {
        icon: "mdi:file-document-edit-outline",
      },
      quoted: {
        icon: "mdi:clipboard-text-outline",
      },
      open: {
        icon: "mdi:folder-open-outline",
      },
    },
  },
};


const getStatusType = (status) => {
  const lowercaseStatus = status?.toLowerCase();
  const type = Object.keys(statusConfig).find((type) =>
    Object.keys(statusConfig[type].statuses).includes(lowercaseStatus)
  );

  return type || "error";
};

export const formatTime = (timeString) => {
  if (timeString) {
    const [hours, minutes] = timeString?.split(":");
    const formattedHours = hours % 12 || 12;
    const period = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${formattedHours}:${minutes}`;
    return formattedTime;
  }
};

const getStatusIcon = (status) => {
  const lowercaseStatus = status?.toLowerCase();
  const type = getStatusType(lowercaseStatus);
  return (
    statusConfig[type]?.statuses[lowercaseStatus]?.icon || "mdi:alert-circle"
  );
};

export const successAlert = (msg) => {
    Swal.fire({
      icon: "success",
      title: "Done",
      text: msg,
    });
  };
  
  export const errorAlert = (msg) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: msg,
    });
  };
  
  export const loadingAlert = (msg) => {
    // Show loading animation
    Swal.fire({
      title: "Processing...",
      html: msg,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  export const formatedPrice = (value) => {
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: value <= 100000 ? 2 : 0,
      maximumFractionDigits: value <= 100000 ? 2 : 0,
    }).format(value || 0);
  
    return formattedValue;
  };

  export const renderStatusBadge = (status) => {
    const type = getStatusType(status);
    const { color } = statusConfig[type];
    const icon = getStatusIcon(status);
    return (
      //   <div
      //   className={
      //     "bg-[#E7F7EF]  ease-in-out text-[#0CAF60] capitalize "
      //   }
      // >
      <div
        className={`inline-flex items-center  rounded-lg  hover:scale-105 duration-300 whitespace-nowrap font-medium ${color} capitalize transition-transform duration-300 ease-in-out px-3 py-2`}
      >
        <Icon icon={icon} className="w-4 h-4 mr-1" />
        {status}
      </div>
    );
  };

  export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${month}/${day}/${year}`;
  };


  export function getCompanyFromURL() {
    // Get the current URL
    const url = new URL(window.location.href);
  
    // Use URLSearchParams to extract the query parameters
    const params = new URLSearchParams(url.search);
  
    // Get the value of the 'company' parameter
    const company = params.get("company");
  
    // Return the value
    return company;
  }